@import 'style/constants.module.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  @extend .h-full;
  @extend .w-full;
  @extend .text-base;
  @extend .font-normal;
  font-family: 'Lato', 'Helvetica Neue', -apple-system, sans-serif;

  ::-webkit-scrollbar {
    @extend .bg-light-grey;
    -webkit-appearance: none;
    width: 15px;
    overflow: visible;
    background-color: #ddd;
  }
  ::-webkit-scrollbar-thumb {
    @extend .rounded;
    background-color: $grey;
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  }
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]:after {
  content: attr(data-tooltip);
  position: absolute;
  left: 50%;
  top: 100%;
  background-color: #3c4257;
  color: #f7fafc;
  width: max-content;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  z-index: 9999;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  transform: translateX(-50%) translateY(4px);
  white-space: nowrap;
}

[data-tooltip]:hover:after {
  opacity: 1;
  visibility: visible;
}

div[data-tooltip]:after {
  left: 5px !important;
  transform: translateY(4px);
}

kbd {
  display: inline-block;
  color: #3c4257;
  background-color: #f7fafc;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: inset 0 -1px 0 #e3e8ee;
  vertical-align: middle;
  padding: 3px 5px;
  min-width: 2em;
  margin-left: 2px;
  margin-right: 2px;
}

h1 {
  @extend .text-primary;
  @extend .text-2xl;
  @extend .font-bold;
}

h2 {
  @extend .text-primary;
  @extend .text-xl;
  @extend .font-bold;
}

h3 {
  @extend .text-primary;
  @extend .text-lg;
  @extend .font-bold;
}

button {
  @extend .inline-block;
  @extend .bg-blue;
  @extend .rounded;
  @extend .text-primary;
  @extend .cursor-pointer;
  @extend .border-1px;
  @extend .border-solid;
  @extend .border-transparent;
  @extend .font-semibold;
  @extend .text-sm;
  @extend .transition-all;
  @extend .text-white;
  @extend .text-center;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 10px;
  padding-left: 10px;
  font-family: inherit;
}

.button-shadow-hover:hover {
  @extend .heavy-shadow;
  transform: translate(-1px, -1px);
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}

select:hover {
  @extend .button-shadow-hover;
}

button.outline {
  @extend .bg-white;
  @extend .border-1px;
  @extend .border-solid;
  @extend .border-black;
  @extend .text-primary;
}

button:focus {
  @extend .heavy-shadow;
  transform: translate(-1px, -1px);
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
}

input,
textarea {
  font-size: inherit;
  font-family: inherit;
  border-radius: 4px;
  transition: all 0.2s ease-in-out 0s;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid black;
  background-color: rgb(255, 255, 255);
  padding: 11px 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  resize: vertical;
}

textarea:read-only {
  resize: none;
}

input:read-only,
textarea:read-only {
  cursor: default;
}

input:read-write:hover,
textarea:read-write:hover {
  @extend .hover-bg-light-grey;
}

input:read-only:focus,
textarea:read-only:focus {
  outline: none;
}

select {
  @extend .hover-bg-light-grey;
  font-size: inherit;
  font-family: inherit;
  border-radius: 4px;
  transition: all 0.2s ease-in-out 0s;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid black;
  background-color: rgb(255, 255, 255);
  padding: 11px 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  user-select: none;
  cursor: pointer;
}

select:focus {
  @extend .heavy-shadow;
  transform: translate(-1px, -1px);
}

input::placeholder,
textarea::placeholder {
  font-size: inherit;
}

.with-focus:focus {
  @extend .heavy-shadow;
  transform: translate(-1px, -1px);
}

/***

  --------------------------
  Display
  --------------------------
  
***/

.hidden {
  display: none;
}

.table {
  display: table;
}

.table-caption {
  display: table-caption;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.webkit-box {
  display: -webkit-box;
}

.inline-flex {
  display: inline-flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-none {
  flex: none;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-right {
  justify-content: right;
}

.space-between {
  justify-content: space-between;
}

.space-evenly {
  justify-content: space-evenly;
}

.content-center {
  align-content: center;
}

.self-center {
  align-self: center;
}

.gap-8px {
  gap: 8px;
}

.gap-16px {
  gap: 16px;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.sm-grid-cols-1 {
  @media screen and (max-width: $mobile-breakpoint) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.grid-flow-col {
  grid-auto-flow: column;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.auto-rows-max {
  grid-auto-rows: max-content;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.box-inherit {
  box-sizing: inherit;
}

.orient-vertical {
  -webkit-box-orient: vertical;
}

/***

  --------------------------
  Position
  --------------------------
  
***/

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/***

  --------------------------
  Visibility
  --------------------------
  
***/

.visible {
  visibility: visible;
}

.visibility-hidden {
  visibility: hidden;
  transform: translateX(-10000px) translateY(-10000px);
}

.hover-visible:hover {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

/***

  --------------------------
  Backgrounds
  --------------------------
  
***/

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  background-color: white;
}

.bg-light-grey {
  background-color: $light-grey;
}

.hover-bg-grey:hover {
  background-color: $grey !important;
}

.hover-bg-light-grey:hover {
  background-color: $light-grey;
}

.hover-bg-light-red:hover {
  background-color: $light-red;
}

.hover-bg-really-light-red:hover {
  background-color: $really-light-red;
}

.hover-bg-light-pink:hover {
  background-color: $light-pink;
}

.bg-really-light-grey {
  background-color: $really-light-grey;
}

.bg-really-light-yellow {
  background-color: $really-light-yellow !important;
}

.bg-grey {
  background-color: $grey;
}

.bg-grey-20 {
  background-color: rgba(red($grey), green($grey), blue($grey), 0.2);
}

.bg-grey-40 {
  background-color: rgba(red($grey), green($grey), blue($grey), 0.4);
}

.bg-blue {
  background-color: $blue;
}

.bg-light-blue {
  background-color: $light-blue;
}

.bg-really-light-blue {
  background-color: $really-light-blue !important;
}

.bg-red {
  background-color: $red;
}

.bg-light-red {
  background-color: $light-red;
}

.bg-really-light-red {
  background-color: $really-light-red;
}

.bg-light-pink {
  background-color: $light-pink;
}

.bg-green {
  background-color: $green;
}

.bg-light-green {
  background-color: $light-green;
}

.bg-orange {
  background-color: $orange;
}

.bg-light-orange {
  background-color: $light-orange;
}

.bg-light-yellow {
  background-color: $light-yellow;
}

.bg-alert {
  background-color: $alert-color;
}

/***

  --------------------------
  Opacity
  --------------------------
  
***/

.opacity-0 {
  opacity: 0;
}

.hover-opacity-1:hover {
  opacity: 1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-40 {
  opacity: 0.4;
}

/***

  --------------------------
  Text
  --------------------------
  
***/

.text-inherit {
  color: inherit;
}

.text-grey {
  color: grey;
}

.text-primary {
  color: $text-primary;
}

.text-secondary {
  color: $text-secondary;
}

.text-white {
  color: white;
}

.text-red {
  color: $red;
}

.text-dark-green {
  color: $dark-green;
}

.text-darker-grey {
  color: $darker-grey;
}

.text-green {
  color: $green;
}

.text-blue {
  color: $blue;
}

.text-base {
  font-size: 1.025rem;
  line-height: 1.25rem;
}

.text-base-sm {
  font-size: 0.95rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: 0.8rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.9rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.15rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.3rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.6rem;
  line-height: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.hover-font-bold:hover {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.underline {
  text-decoration-line: underline;
}

.capitalise {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.align-middle {
  vertical-align: middle;
}

/***

  --------------------------
  Heights
  --------------------------
  
***/

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-fit {
  height: fit-content;
}

.h-inherit {
  height: inherit;
}

.h-400px {
  height: 400px;
  min-height: 400px;
  max-height: 400px;
}

/***

  --------------------------
  Widths
  --------------------------
  
***/

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.w-fit-content {
  width: fit-content;
}

.w-inherit {
  width: inherit;
}

.sm-w-100px {
  @media screen and (max-width: $mobile-breakpoint) {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
  }
}

.sm-w-200px {
  @media screen and (max-width: $mobile-breakpoint) {
    min-width: 200px;
    max-width: 200px;
    width: 200px;
  }
}

.w-100px {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}

.w-250px {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}

/***

  --------------------------
  Padding
  --------------------------
  
***/

.p-16px {
  padding: 16px;
}

.p-20px {
  padding: 20px;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.p-20 {
  padding: 5rem;
}

.p-10px {
  padding: 10px;
}

.px-10px {
  padding-left: 10px;
  padding-right: 10px;
}

.px-20px {
  padding-left: 20px;
  padding-right: 20px;
}

.px-30px {
  padding-left: 30px;
  padding-right: 30px;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-10px {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-30px {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-4 {
  padding-bottom: 0.5rem;
}

.pt-20px {
  padding-top: 20px;
}

/***

  --------------------------
  Z Index
  --------------------------
  
***/

.z-100 {
  z-index: 100;
}

/***

  --------------------------
  Margin
  --------------------------
  
***/

.m-10px {
  margin: 10px;
}

.my-20px {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-30px {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mb-15px {
  margin-bottom: 15px;
}

.mb-20px {
  margin-bottom: 20px;
}

.mb-30px {
  margin-bottom: 30px;
}

.mb-50px {
  margin-bottom: 50px;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-10px {
  margin-top: 10px;
}

.mt-20px {
  margin-top: 20px;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-10px {
  margin-right: 10px;
}

.mr-20px {
  margin-right: 20px;
}

.ml-10px {
  margin-left: 10px;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

/***

  --------------------------
  Borders
  --------------------------
  
***/

.border-0 {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.border-1px {
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.border-2px {
  border-top-width: 2px;
  border-left-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
}

.border-v-2px {
  border-top-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}

.border-b-1px {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}

.border-b-2px {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}

.border-b-3px {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 3px;
}

.border-t-1px {
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.border-t-2px {
  border-top-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.border-r-1px {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
}

.border-r-2px {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 2px;
  border-bottom-width: 0px;
}

.border-l-1px {
  border-top-width: 0px;
  border-left-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.border-solid {
  border-style: solid;
}

.hover-border-solid:hover {
  border-style: solid;
}

.border-light-grey {
  border-color: $light-grey;
}

.border-darker-grey {
  border-color: $darker-grey;
}

.border-grey {
  border-color: $grey;
}

.hover-border-grey:hover {
  border-color: $grey;
}

.border-black {
  border-color: black;
}

.border-red {
  border-color: $red;
}

.border-inherit {
  border-color: inherit;
}

.border-transparent {
  border-color: transparent;
}

.border-blue {
  border-color: $blue;
}

.rounded {
  border-radius: 0.25rem; /* 4px */
}

/***

  --------------------------
  Overflow
  --------------------------
  
***/

.overflow-auto {
  overflow: auto;
}

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/***

  --------------------------
  Cursors
  --------------------------
  
***/

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-help {
  cursor: help;
}

.cursor-grab {
  cursor: grab;
}

.cursor-resize {
  cursor: ew-resize;
}

.cursor-vertical-resize {
  cursor: ns-resize;
}

/***

  --------------------------
  Shadow
  --------------------------
  
***/

.shadow {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.16);
}

.hover-shadow:hover {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.16);
}

.heavy-shadow {
  box-shadow: rgba(9, 9, 9, 0.2) 4px 4px 0px 0px;
}

.hover-heavy-shadow:hover {
  transform: translate(-1px, -1px);
  box-shadow: rgba(9, 9, 9, 0.2) 4px 4px 0px 0px;
}

.bottom-shadow {
  box-shadow: rgba(0, 30, 43, 0.1) 0px 4px 4px 0px;
}

.top-shadow {
  box-shadow: rgba(0, 30, 43, 0.1) 0px -4px 4px 0px;
}

/***

  --------------------------
  Select
  --------------------------
  
***/

.allow-select {
  -webkit-touch-callout: auto !important;
  -webkit-user-select: auto !important;
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
  user-select: auto !important;
}

.select-none {
  user-select: none;
}

.select-text {
  user-select: text;
}

/***

  --------------------------
  Transitions
  --------------------------
  
***/

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/***

  --------------------------
  Animations
  --------------------------
  
***/

@keyframes floatup {
  20% {
    opacity: 0.999;
  }

  100% {
    transform: translate3d(0%, -17px, 0);
    opacity: 0;
  }
}

.copying {
  animation: floatup 0.5s ease-in-out;
  animation-fill-mode: forwards;
  position: absolute;
  left: 32px;
}

/***

  --------------------------
  Tables
  --------------------------
  
***/

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

/***
  --------------------------
  Spreadsheet Components
  --------------------------
***/

.small-row-height {
  height: $small-row-height + 0px;
}

.medium-row-height {
  height: $medium-row-height + 0px;
}

.tall-row-height {
  height: $tall-row-height + 0px;
}

.extra-tall-row-height {
  height: $extra-tall-row-height + 0px;
}

.small-placeholder {
  background-color: #fff;
  background-image: linear-gradient(0deg, $grey 1.5%, #fff 0, #fff 50%, $grey 0, $grey 51.5%, #fff 0, #fff);
  background-size: #{$small-row-height * 2}px #{$small-row-height * 2}px;
}

.medium-placeholder {
  background-color: #fff;
  background-image: linear-gradient(0deg, $grey 0.75%, #fff 0, #fff 50%, $grey 0, $grey 50.75%, #fff 0, #fff);
  background-size: #{$medium-row-height * 2}px #{$medium-row-height * 2}px;
}

.tall-placeholder {
  background-color: #fff;
  background-image: linear-gradient(0deg, $grey 0.5%, #fff 0, #fff 50%, $grey 0, $grey 50.5%, #fff 0, #fff);
  background-size: #{$tall-row-height * 2}px #{$tall-row-height * 2}px;
}

.extra-tall-placeholder {
  background-color: #fff;
  background-image: linear-gradient(0deg, $grey 0.25%, #fff 0, #fff 50%, $grey 0, $grey 50.25%, #fff 0, #fff);
  background-size: #{$extra-tall-row-height * 2}px #{$extra-tall-row-height * 2}px;
}

.cell-comments {
  box-sizing: border-box;
  border-left: none;
  border-right: 1px solid $light-grey;
  display: table;
}

.cell-comments::after {
  position: absolute;
  top: 0;
  right: 0;
  content: '';
}

.cell-value {
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  width: 100vw;
  background-color: inherit;
  padding: 0 5px;
  display: table-cell;
  vertical-align: middle;
}

.cell-value-editing {
  width: 100%;
  background-color: white;
  outline: none;
  margin: 0px;
  padding-left: 5px;
  height: inherit;
}

.cell:focus,
.cell-value:focus,
.cell-value-editing:focus {
  outline: none;
}
.cell.focus,
.cell-value.focus {
  border: 1px solid $blue;
}

.cell-value-editing.focus {
  border: 1px solid $blue;
}

/***

  --------------------------
  Custom Components
  --------------------------
  
***/

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 10px 0px;
  user-select: none;
  line-height: 150%;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  border: 1px solid $grey;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.checkmark.disabled,
.checkbox-container.disabled:hover input ~ .checkmark {
  background-color: $light-grey;
  cursor: default;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: $grey;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: $blue;
}

.checkbox-container.disabled input:checked ~ .checkmark {
  background-color: $light-grey;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 6.5px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid $light-grey;
  border-top-color: $darker-grey;
  background: transparent;
  animation: rotate-s-loader 1s linear infinite;
}

.document-toolbar {
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow-x: auto;
  width: fit-content;
  gap: 0px;

  @media screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
  }
}

.mobile-display-none {
  @media screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.pulsate-border {
  border: 3px solid $alert-color;
  animation: border-pulsate 2s infinite;
  font-weight: 600 !important;
}

@keyframes border-pulsate {
  0% {
    border-color: $orange;
  }
  50% {
    border-color: $alert-color;
  }
  100% {
    border-color: $orange;
  }
}

.sign-up-split {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;
  gap: 10px;
}

.sign-up-split::before {
  background: #e0e0e0;
  content: '';
  height: 1px;
  width: 200px;
}

.sign-up-split::after {
  background: #e0e0e0;
  content: '';
  height: 1px;
  width: 200px;
}

.CodeMirror {
  height: auto !important;
}

.editor-editable figure:not(:has(img, video, iframe)) {
  cursor: not-allowed;
  pointer-events: none;
}

.RichEditor-editor ul,
.RichEditor-editor ol {
  margin: 0 0 5px 20px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  /* margin-bottom: 5px; */
  user-select: none;
  display: flex;
}

.RichEditor-styleButton {
  color: $grey;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: $blue;
}

.RichEditor-styleButton {
  display: flex !important;
  color: black !important;
  margin-right: 12px !important;
  padding: 0 !important;
}

.RichEditor-activeButton {
  color: blue !important;
}

.draftJsMentionPlugin__mention__29BEd {
  color: blue;
  background: transparent;
  padding: 0 3px;
  border-radius: 2px;
  font-weight: 700;
  cursor: default;
}

.editor {
  box-sizing: border-box;
  border-radius: 4px;
  cursor: text;
  border-radius: 2px;
  background: transparent !important;
  line-height: 1.7;
}

.editor li {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.draftJsMentionPlugin__mention__29BEd,
.mention {
  color: #4a85bb;
  text-decoration: none;
  cursor: pointer;
}

.editor-focused img {
  border: 1px solid black;
}

.draftJsMentionPlugin__mentionSuggestions__2DWjA,
.mentionSuggestions {
  border-top: 1px solid #eee;
  background: #fff;
  border-radius: 2px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  margin: -16px;
}

.mentionSuggestionsEntryContainer {
  display: table;
  width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
  display: table-cell;
  vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
  width: 100%;
  padding-left: 8px;
}

.mentionSuggestionsEntry {
  padding: 7px 10px 3px 10px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.mentionSuggestionsEntry:active {
  background-color: #cce7ff;
}

.mentionSuggestionsEntry:focus {
  background-color: #e6f3ff;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mentionSuggestionsEntryTitle {
  font-size: 80%;
  color: #a7a7a7;
}

.mentionSuggestionsEntryAvatar {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.customPopover img {
  height: 10px;
  width: 10px;
}

.toolbar-element {
  @extend .truncate;
  border-radius: 4px;
  margin: 5px;
  padding: 5px;
  min-width: 25px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  justify-content: flex-start;
}

.toolbar-button {
  @extend .toolbar-element;
  cursor: pointer;
  transition: 0.085s background-color ease-in;
}

.toolbar-button:hover {
  background-color: $light-grey;
}

.toolbar-button-active {
  @extend .toolbar-element;
  background-color: $light-teal;
  cursor: pointer;
  transition: 0.085s border ease-in;
}

.toolbar-button-active:hover {
  border: 1px solid $grey;
}

.toolbar-button-error {
  @extend .toolbar-element;
  background-color: $light-red;
  cursor: pointer;
  transition: 0.085s border ease-in;
}

.toolbar-button-error:hover {
  border: 1px solid $grey;
}

.toolbar-button-text {
  margin-left: 4px;
}

.toolbar-button-pulsate-background {
  @extend .toolbar-element;
  cursor: pointer;
  background: rgba($light-red, 1);
  box-shadow: 0 0 0 0 rgba($light-red, 1);
  transform: scale(1);
  animation: pulse-pink 2s infinite;
  border-radius: 50%;
}

@keyframes pulse-pink {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba($light-red, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.toolbar-button-group-active {
  @extend .toolbar-element;
  background-color: $light-yellow;
  cursor: pointer;
  transition: 0.085s border ease-in;
}

.toolbar-button-group-active:hover {
  border: 1px solid $grey;
}

.toolbar-button-disabled {
  @extend .toolbar-element;
  opacity: 0.2;
}

.process-margin {
  margin-top: 30px;
  margin-bottom: 75px;
  margin-left: 150px;
  margin-right: 150px;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 40px;
    margin-bottom: 75px;
    margin-left: 30px;
    margin-right: 30px;
  }
}

.modal-width-small {
  width: 35%;
  max-width: calc(100% - 80px);

  @media screen and (max-width: $mobile-breakpoint) {
    width: 100%;
    max-width: 100%;
  }
}

.modal-width-medium {
  width: 75%;
  max-width: calc(100% - 80px);

  @media screen and (max-width: $mobile-breakpoint) {
    width: 100%;
    max-width: 100%;
  }
}

.modal-padding {
  padding: 40px;

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 10px;
  }
}

.spinner-chart {
  @extend .toolbar-element;
  cursor: pointer;
  transition: 0.085s background-color ease-in;
  justify-content: space-around;
}
